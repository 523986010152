import React from 'react'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import { Image } from './Images'
import clsx from 'clsx'
import { KretzIconCircle } from '../icons/KretzIconCircle'
import { Hidden } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  root: {
    zIndex: 999,
    fontFamily: `'Cormorant Garamond', serif`,
    backgroundColor: '#E0E5DE',
    paddingTop: theme.spacing(18),

    [theme.breakpoints.between('xs', 'sm')]: {
      paddingTop: theme.spacing(12),
    },
  },
  imgBox: {
    paddingRight: '1rem',
  },
  detail: {
    fontFamily: `'Cormorant Garamond', serif`,
    marginTop: theme.spacing(5),
    textAlign: 'center',
    fontSize: theme.typography.pxToRem(25),
    [theme.breakpoints.between('md', 'md')]: {
      fontSize: theme.typography.pxToRem(20),
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      fontSize: theme.typography.pxToRem(18),
    },
  },
  mAuto: {
    margin: 'auto',
  },
  title: {
    fontSize: theme.typography.pxToRem(80),
    textTransform: 'uppercase',
    textAlign: 'center',
    width: '80%',
    zIndex: 9,
    [theme.breakpoints.between('md', 'md')]: {
      fontSize: theme.typography.pxToRem(50),
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      fontSize: theme.typography.pxToRem(30),
    },
  },
  circle: {
    height: '5em',
    width: 'auto',
    position: 'absolute',
    marginTop: theme.spacing(5),
    zIndex: 9,
    [theme.breakpoints.between('lg', 'lg')]: {
      marginTop: theme.spacing(1),
    },
    [theme.breakpoints.between('md', 'md')]: {
      height: '3em',
      marginTop: theme.spacing(2),
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      height: '3em',
      position: 'inherit',
      marginTop: theme.spacing(0),
    },
  },
  circleDiv: {
    justifyContent: 'flex-end',
    marginRight: theme.spacing(3),
    [theme.breakpoints.between('xs', 'sm')]: {
      marginRight: theme.spacing(0),
      justifyContent: 'center',
      paddingBottom: theme.spacing(2),
    },
  },
}))

interface LifeStlyePageSliderProps {
  title: string
  mainImg: string
  mainImgAlt: string
  leftImg: string
  leftImgAlt: string
  rightImg: string
  rightImgAlt: string
  details: string
}

export const LifeStlyePageSlider: React.FC<LifeStlyePageSliderProps> = (props) => {
  const { title, mainImg, leftImg, rightImg, mainImgAlt, rightImgAlt, leftImgAlt, details } = props

  const classes = useStyles()
  return (
    <Grid container className={classes.root} justifyContent="center">
      <Hidden mdUp>
        <Grid container item xs={12} className={classes.circleDiv}>
          <KretzIconCircle className={classes.circle} />
        </Grid>
      </Hidden>
      <Grid item xs={12} container justifyContent="center">
        <Grid item xs={12} className={classes.title}>
          {title}
        </Grid>

        <Hidden smDown>
          <Grid container item xs={12} className={classes.circleDiv}>
            <KretzIconCircle className={classes.circle} />
          </Grid>
        </Hidden>
        <Grid container item xs={12}>
          <Grid container item xs={3} className={clsx(classes.mAuto, classes.imgBox)}>
            <Image src={leftImg} alt={leftImgAlt} layout="CONSTRAINED" />
          </Grid>
          <Grid container item xs={6} className={clsx(classes.imgBox)}>
            <Image src={mainImg} alt={mainImgAlt} layout="CONSTRAINED" />
          </Grid>
          <Grid container item xs={3} className={clsx(classes.mAuto)}>
            <Image src={rightImg} alt={rightImgAlt} layout="CONSTRAINED" />
          </Grid>
        </Grid>
        <Grid container justifyContent="center">
          <Grid container item md={3} xs={1} />
          <Grid item md={6} xs={10} className={classes.detail}>
            {details}
          </Grid>
          <Grid container item md={3} xs={1} />
        </Grid>
      </Grid>
    </Grid>
  )
}

export default LifeStlyePageSlider
